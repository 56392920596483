define("discourse/plugins/discourse-local-dates/discourse/templates/modal/discourse-local-dates-create-modal", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <DiscourseLocalDatesCreateForm
    @config={{this.config}}
    @insertDate={{this.insertDate}}
  />
  */
  {
    "id": "lejbGJvu",
    "block": "[[[8,[39,0],null,[[\"@config\",\"@insertDate\"],[[30,0,[\"config\"]],[30,0,[\"insertDate\"]]]],null]],[],false,[\"discourse-local-dates-create-form\"]]",
    "moduleName": "discourse/plugins/discourse-local-dates/discourse/templates/modal/discourse-local-dates-create-modal.hbs",
    "isStrictMode": false
  });
});